import React from "react";
import "./temp.css";
import breedsmaLogo from "../../images/breedsmaplainfull.png";

export default function Temp() {
  return (
    <div className="scrolling-container">
      <div className="scrolling-text">
        <img src={breedsmaLogo} alt="Breedsma Logo" />
      </div>
    </div>
  );
}
